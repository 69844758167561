var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"informative-sec"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"informative-slider"},[_c('ClientOnly',[_c('VueSlickCarousel',{attrs:{"arrows":false,"dots":false,"slidesToShow":4,"autoplay":false,"center":false,"variableWidth":true,"responsive":[
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 540,
              settings: {
                slidesToShow: 1,
                initialSlide: _vm.initialSlideMobile && _vm.initialSlideMobile,
                draggable: false,
                swipe: false,
                touchMove: false,
                autoplay: true,
              },
            } ]}},_vm._l((_vm.informativeSlider.slides),function(slide,index){return _c('div',{key:index,staticClass:"cms-block-content"},[_c('b-link',{staticClass:"informative-wrap",attrs:{"to":slide.link}},[_c('b-img',{attrs:{"src":slide.media.url}}),_c('div',{domProps:{"innerHTML":_vm._s(slide.description)}})],1)],1)}),0)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }