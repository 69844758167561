<template>
  <div class="informative-sec">
    <div class="container">
      <div class="informative-slider">
        <ClientOnly>
          <VueSlickCarousel
            :arrows="false"
            :dots="false"
            :slidesToShow="4"
            :autoplay="false"
            :center="false"
            :variableWidth="true"
            :responsive="[
              {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 4,
                },
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 540,
                settings: {
                  slidesToShow: 1,
                  initialSlide: initialSlideMobile && initialSlideMobile,
                  draggable: false,
                  swipe: false,
                  touchMove: false,
                  autoplay: true,
                },
              },
            ]"
          >
            <div
              class="cms-block-content"
              v-for="(slide, index) in informativeSlider.slides"
              :key="index"
            >
              <b-link :to="slide.link" class="informative-wrap">
                <b-img :src="slide.media.url"></b-img>
                <div v-html="slide.description"></div>
              </b-link>
            </div>
          </VueSlickCarousel>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ClientOnly from "vue-client-only";
export default {
  name: "InformativeLine",
  components: {
    VueSlickCarousel,
    ClientOnly,
  },
  props: {
    initialSlideMobile: Number,
  },
  computed: {
    headerInforativeCms() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "header_informative_text"
      );
    },
    informativeSlider() {
      const data = this.$store.getters["sliders/getSliderByIdentifier"](
        "informative_text_slider"
      );
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
